import React from 'react'

// CSS
import './BrandSlider.scss'
import { useStaticQuery, graphql } from 'gatsby'

// Third Party
import _ from 'lodash'
import Slider from 'react-slick'

const BrandSlider = ({ className, merken, title }) => {
  const {
    allWordpressWpMerk: { edges: brandList },
  } = useStaticQuery(graphql`
    {
      allWordpressWpMerk {
        edges {
          node {
            acf {
              brand_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`brand-slider ${className && `${className}`}`}>
      {merken ? (
        <div className="container">
          <div className="brand-slider-title-container d-flex justify-content-center">
            <h2 className="brand-slider-title text-center text-lg-left pb-3 pb-lg-0 font-size-xl font-weight-xl">
              {title}
            </h2>
          </div>
          <Slider
            infinite
            speed={200}
            slidesToShow={merken.length >= 5 ? 5 : merken.length}
            slidesToScroll={1}
            centerMode
            autoplay
            autoplaySpeed={8000}
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: merken.length >= 2 ? 2 : merken.length,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {_.map(merken, (brand, index) => (
              <div key={index}>
                <img
                  alt="src"
                  src={brand.acf.brand_image.localFile.publicURL}
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="container">
          <div className="brand-slider-title-container d-flex justify-content-center">
            <h2 className="brand-slider-title font-size-xl font-weight-xl">
              {title}
            </h2>
          </div>
          <Slider
            infinite
            speed={200}
            slidesToShow={5}
            slidesToScroll={1}
            autoplay
            autoplaySpeed={8000}
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: brandList.length >= 2 ? 2 : brandList.length,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {_.map(brandList, (brand, index) => (
              <div key={index}>
                <img
                  alt="src"
                  src={brand.node.acf.brand_image.localFile.publicURL}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  )
}

export default BrandSlider
